import { IEnvironment } from '@environments/classes/IEnvironment';

export const environment: IEnvironment = {
  name: 'TEMP-DEV',
  production: false,
  apiUrl: 'https://api.diak.dev.whc.attrecto-dev.com/api',
  authStorageStrategy: 'cookie',
  cookieDomain: '.dev.whc.attrecto-dev.com',
  backendVersion: 'v1',
  assetUrl: 'https://api.diak.dev.whc.attrecto-dev.com',
  atsType: 'student',
  appInsights: undefined,
};
